<template>
  <div class="sidebar">
    <div class="logo">
      <a href="#" class="simple-text logo-mini">
        <div class="logo-img">
          <img :src="imgLogo" alt="" />
        </div>
      </a>

      <a href="#" class="simple-text logo-normal">
        {{ title }}
      </a>
    </div>
    <div class="sidebar-wrapper">
      <slot name="content"></slot>
      <ul class="nav">
        <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
        <slot>
          <sidebar-link
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :to="link.path"
            :link="link"
          />
        </slot>
      </ul>
    </div>
  </div>
</template>

<script>
import SidebarLink from './SidebarLink.vue';

export default {
  components: {
    SidebarLink
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    imgLogo: {
      type: String,
      default: require('@/assets/img/logo_lm1.png')
    },
    sidebarLinks: {
      type: Array,
      default: () => []
    },
    autoClose: {
      type: Boolean,
      default: true
    }
  },
  provide() {
    return {
      autoClose: this.autoClose
    };
  }
};
</script>
