<template>
  <li class="nav-item">
    <router-link v-bind="$attrs" @click="hideSidebar">
      <div class="nav-item-content">
        <slot>
          <m-icon :name="link.icon" />
          <p>{{ link.name }}</p>
        </slot>
      </div>
    </router-link>
  </li>
</template>
<script>
export default {
  inject: {
    autoClose: {
      default: true
    }
  },
  props: {
    link: {
      type: [String, Object],
      default: () => ({
        name: '',
        path: '',
        icon: ''
      })
    },
    tag: {
      type: String,
      default: 'router-link'
    }
  },
  methods: {
    hideSidebar() {
      if (this.autoClose && this.$sidebar && this.$sidebar.showSidebar === true) {
        this.$sidebar.displaySidebar(false);
      }
    }
  }
};
</script>
<style></style>
